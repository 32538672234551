import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main>
      <div id="main">
      <article className="post featured">
      <header className='major'>
        <h2>Erreur 404</h2>
        <h3>Page non trouvée</h3>
        <p>
        Cette page est désormais manquante ou n'a jamais existé.
          <br/>
          Retour à <em><Link to="/"> l'ACCUEIL</Link></em>
          </p>
      </header>
      </article>
      </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
